import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider/AuthProvider";

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const { isLogin, userInfo } = useAuth();
  const location = useLocation();

  if (isLogin && userInfo.access_token) {
    return <>{props.children}</>;
  }
  return (
    <Navigate to="/auth/login" replace state={{ path: location.pathname }} />
  );
};
