import { LoadState } from "../../constants/enums";
import { IRectangle } from "../../views/template/ImageWithRectanglesSVG.types";
import { defaultExcelTemplateState } from "./excel-template/excel-template.state";
import { IExcelTemplateState } from "./excel-template/excel-template.types";

export interface ITemplatesState extends IExcelTemplateState {
  list: ITemplate[];
  loading: LoadState;
  totalRecords: number;
  template: ITemplate;
  templateLoading: LoadState;
  error: string | null;
}

export interface ITemplate {
  template_uuid: string | null;
  box_count: string | null;
  template_name: string | null;
  column_mapping: any;

  file_type: string | null;
  cropped_images?: string[];
  base_file_path: string | null;
  page_box_coordinate: IRectangle[];
  first_img_paths?: string;
  item_labeling:{
    [key: string]:  IRectangle[]
  };
  status: "UNAUTHORIZE" | "ACTIVE" | "INACTIVE" | "BLOCKED";
  template_unique_id?: string | null;
  create_ts?: string | null;
  insert_ts?: string | null;
}

export const initialTemplatesState: ITemplatesState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  template: {
    template_uuid: null,
    template_name: null,
    file_type: null,
    base_file_path: null,
    page_box_coordinate: [],
    box_count: "0",
    item_labeling: {},
    column_mapping: {},
    status: "ACTIVE",
  },
  templateLoading: LoadState.NotLoaded,
  error: null,
  excel_template: defaultExcelTemplateState["excel_template"]
};
