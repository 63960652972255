import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_TEMPLATES_ROUTE } from "./Templates.constants";
import { MODULE_IDS } from "../../constants/enums";

const Templates = Loadable(
  lazy(() =>
    import("./templates").then(({ Templates }) => ({
      default: Templates,
    }))
  )
);

const ManageTemplate = Loadable(
  lazy(() =>
    import("./ManageTemplate").then(({ ManageTemplate }) => ({
      default: ManageTemplate,
    }))
  )
);


const ManageExcelTemplate = Loadable(
  lazy(() =>
    import("./excelTemplate/ManageExcelTemplate").then(({ ManageExcelTemplate }) => ({
      default: ManageExcelTemplate,
    }))
  )
);

export const templatesRoutes = [
  {
    path: APP_TEMPLATES_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[]}>
        <Templates />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_TEMPLATES_ROUTE}/manage-pdf-template`,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[]}>
        <ManageTemplate />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_TEMPLATES_ROUTE}/manage-pdf-template/:templateId`,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[]}>
        <ManageTemplate />
      </AuthorizedRoute>
    ),
  },

  {
    path: `${APP_TEMPLATES_ROUTE}/manage-excel-template`,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[]}>
        <ManageExcelTemplate />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_TEMPLATES_ROUTE}/manage-excel-template/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[]}>
        <ManageExcelTemplate />
      </AuthorizedRoute>
    ),
  },
]