import { ActionType } from "typesafe-actions";
import * as workOrderAction from "./workOrder.actions";
import * as workOrderJobAction from "./workOrderJob.actions";

export type WorkOrderActions = ActionType<
  typeof workOrderAction & typeof workOrderJobAction
>;

export * from "./workOrder.types";
export * from "./workOrder.state";
export * from "./workOrder.actions";
export * from "./workOrderJob.actions";
export * from "./workOrder.reducer";
