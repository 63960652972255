import { ActionType } from "typesafe-actions";

import * as ProductActions from "./product.actions";
import { ProductTemplateActions } from "./product-templates";
import { ProductAddOnActions } from "./product-addon";

type Actions = typeof ProductActions &
  ProductTemplateActions &
  ProductAddOnActions;

export type ProductActionsType = ActionType<Actions>;

export * from "./products.types";
export * from "./products.state";
export * from "./product.actions";
export * from "./product.reducer";

export * from "./product-templates";
