import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";

import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IUserCreation, IUser } from "./userProfile.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";

export const FETCH_USERS_LIST_PROGRESS = "FETCH_USERS_LIST_PROGRESS";
export const FETCH_USERS_LIST_SUCCESS = "FETCH_USERS_LIST_SUCCESS";
export const FETCH_USERS_LIST_FAILED = "FETCH_USERS_LIST_FAILED";

export const fetchUsersListProgress = () => action(FETCH_USERS_LIST_PROGRESS);
export const fetchUsersListSuccess = (
  data: IUser[],
  totalNumberOfRecords: number,
) => action(FETCH_USERS_LIST_SUCCESS, { data: data, totalNumberOfRecords });
export const fetchUsersListFailed = () => action(FETCH_USERS_LIST_FAILED);

export const fetchUsersListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchUsersListProgress());
        let url = "";
        if (status !== "-1") {
          url = "&status=" + status;
        }

        let finalUrl = `/user/get-user?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
        if (searchType.length > 0 && searchValue) {
          finalUrl = `/user/get-user?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&columns=${[
            searchType,
          ]}&value=${searchValue}`;
        }
        const res = await api.get(finalUrl);

        const data: IUser[] = res.data.data;
        dispatch(fetchUsersListSuccess(data, res.data.totalRecords));
      } catch (err: any) {
        dispatch(fetchUsersListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const FETCH_USERS_PROGRESS = "FETCH_USERS_PROGRESS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

export const fetchUsersProgress = () => action(FETCH_USERS_PROGRESS);
export const fetchUsersSuccess = (data: IUser) =>
  action(FETCH_USERS_SUCCESS, { data: data });
export const fetchUsersFailed = (errorMessage: string) =>
  action(FETCH_USERS_FAILED, { errorMessage });

export const fetchUsersAsync =
  (userId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchUsersProgress());
        const res = await api.get(`/user/get-user?user_uuid=${userId}`);
        const data = res.data.data;
        if (data.length > 0) {
          dispatch(fetchUsersSuccess(data[data.length - 1]));
        } else {
          dispatch(
            fetchUsersFailed(
              "Unfortunately, there are no records available at the moment.",
            ),
          );
        }
      } catch (err: any) {
        dispatch(fetchUsersFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertUserAysnc =
  (
    user: IUser,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const {
          email,
          user_password,
          role_id,
          role_value,
          role_uuid,
          role_group,
          role_name,
          module_security,
          user_profile_id,
          user_dim_id,
          create_ts,
          insert_ts,
          full_name,
          ...rest
        } = user;
        await api.post("/user/update-profile", rest);

        dispatch(
          showMessage({
            type: "success",
            message: "User profile is updated successfully",
            displayAs: "snackbar",
          }),
        );
        onCallback(true);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const createNewUserAsync =
  (
    user: IUserCreation,
    onCallback: (isSuccess: boolean, user_uuid?: number) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const { confirmPassword, ...rest } = user;
        const res = await api.post("/user/upsert-user", rest);

        dispatch(
          showMessage({
            type: "success",
            message: "User is created successfully",
            displayAs: "snackbar",
          }),
        );
        onCallback(true, res.data.data.user_uuid);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertUserRoleAsync =
  (
    roleDetails: {
      user_uuid: string;
      role_uuid: string;
    },
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        await api.post("/user/change-user-role", roleDetails);
        dispatch(
          showMessage({
            type: "success",
            message: "User role is updated successfully",
            displayAs: "snackbar",
          }),
        );
        onCallback(true);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };
