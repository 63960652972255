import React from "react";
import { IStandardTableActionsProps } from "./StandardTableActions.types";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
// @ts-ignore
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearch";

import EditIcon from "@mui/icons-material/Edit";
import { ContextMenu } from "../ContextMenu/ContextMenu";
import {
  ContentCopyOutlined,
  Download,
  DescriptionOutlined,
} from "@mui/icons-material";

import { Dialog } from "../Dialogs/Dialog";
import HistoryIcon from "@mui/icons-material/History";
import CommentIcon from "@mui/icons-material/Comment";

export const StandardTableActions: React.FC<IStandardTableActionsProps> = (
  props,
) => {
  const {
    onViewClick,
    onEditClick,
    onViewHistoryClick,
    onCommentClick,
    onDownLoadClick,
    onDownloadPreview,
    onDuplicateClick,
    downloadLoading,
    downloadHidden,
    commentBoxTypeId,
    disableEdit,
    onRequestItemClick,
    more,
  } = props;

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        sx={{
          svg: {
            cursor: "pointer",
          },
        }}
        spacing={2}
      >
        {onViewClick && (
          <Box onClick={onViewClick}>
            <Tooltip title="View">
              <VisibilityIcon color="primary" />
            </Tooltip>
          </Box>
        )}
        {onEditClick && (
          <Box onClick={() => (disableEdit ? null : onEditClick())}>
            <Tooltip title="Edit">
              <EditIcon color={disableEdit ? "disabled" : "primary"} />
            </Tooltip>
          </Box>
        )}
        {onDuplicateClick && (
          <Box onClick={onDuplicateClick}>
            <Tooltip title="Duplicate">
              <ContentCopyOutlined color="primary" />
            </Tooltip>
          </Box>
        )}
        {onViewHistoryClick && (
          <Box onClick={onViewHistoryClick}>
            <Tooltip title="Activity">
              <HistoryIcon color="primary" />
            </Tooltip>
          </Box>
        )}
        {onCommentClick && (
          <Box onClick={onCommentClick}>
            <Tooltip title="Activity">
              <CommentIcon color="primary" />
            </Tooltip>
          </Box>
        )}
        {onDownloadPreview && (
          <Box onClick={onDownloadPreview}>
            <Tooltip title="Preview">
              <ContentPasteSearchOutlinedIcon color="primary" />
            </Tooltip>
          </Box>
        )}
        {onDownLoadClick && !downloadHidden && (
          <Box onClick={!downloadLoading ? onDownLoadClick : undefined}>
            <Tooltip title="Download">
              <Download color="primary" />
            </Tooltip>
          </Box>
        )}
        {onRequestItemClick && (
          <Box onClick={onRequestItemClick}>
            <Tooltip title="Request Drawing">
              <DescriptionOutlined color="primary" />
            </Tooltip>
          </Box>
        )}
        {more && <ContextMenu menuOptions={more.menuItems}></ContextMenu>}
      </Stack>
      {downloadLoading && (
        <Dialog
          open={downloadLoading || false}
          size="sm"
          onClose={() => {}}
          title=""
        >
          <Stack
            minHeight={"200px"}
            justifyContent={"center"}
            direction={"column"}
            alignItems={"center"}
          >
            <CircularProgress size={40} />
            <Typography variant="h3" marginTop={2}>
              Download in progress..
            </Typography>
          </Stack>
        </Dialog>
      )}
    </>
  );
};
