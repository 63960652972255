import React from "react";
import { PageContainer } from "../../components/container/PageContainer";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { ProductInfo } from "./ProductInfo";
import { ProductCarousel } from "./ProductCarousel";
import { ProductDesc } from "./ProductDesc";
import { ProductRelated } from "./ProductRelated";


const BCrumb = [
    {
      to: '/',
      title: 'Home',
    },
    {
      title: 'eCommerce Shop',
    },
  ];

export const ProductDetail: React.FC = () => {

    return(
        <>
          <PageContainer title="Shop Detail" description="this is Shop Detail page">
      {/* breadcrumb */}
      <Breadcrumb title="eCommerce Shop" items={BCrumb} />
      {/* end breadcrumb */}
      <Card 
        sx={{
            borderRadius: '20px',
            boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
          }}
      >
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} lg={6}>
              <Box sx={{ paddingRight: { xs: 0, lg: '50px' } }}>
                <ProductCarousel />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <ProductInfo />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ProductDesc />
      <ProductRelated />
    </PageContainer>
        </>
    )
}